import { ASTOCK_LIST } from "../astock";

export default {
  header: {
    menu: {
      tradingx: "TradingX",
      service: {
        title: "Services",
        subItems: {
          marketmaker: "Market Making",
          digitalAssetManagement: "Digital Asset Management",
          advisory: "Advisory",
        },
      },
      about: {
        title: "About",
        subItems: {
          aboutUs: "About Us",
          aboutCareer: "Careers",
        },
      },
      contactUs: "Contact Us",
    },
  },
  home: {
    menuItem: {
      production: "TradingX",
      service: "Service",
      about: "About",
      contact: "Contact Us",
    },
    intro: {
      whoWeArePart1: "We're ",
      whoWeArePart2: "a leading global ",
      whoWeArePart3: "algorithmic trading ",
      whoWeArePart4: "firm ",
      whoWeArePart5: "in digital assets",
      whatWeDo:
        "We address liquidity and efficiency challenges in the crypto markets through technology and trading, helping sophisticated investors achieve financial outcomes while driving the development of the crypto economy.",
      tradingVolumn: "Trading Volume",
      scientificBasis: "Scientific Data",
      client: "Clients",
    },
    services: {
      title: "The Intersection of Technology and Finance",
      content:
        "Building intelligent, dynamic trading systems to drive efficient capital allocation and financial innovation.",
      aiResearch: {
        title: "AI Research",
        subtitle: "Unleashing the power of AI",
        content:
          "We delve deep into AI, creating outstanding algorithms and training models, leveraging AI's capabilities to identify market opportunities and mitigate risks, expanding financial frontiers, and delivering higher efficiency and success to algorithmic trading.",
      },
      marketmaker: {
        title: "Crypto Market Making",
        subtitle: "An essential role in markets",
        content:
          "As a market maker, we integrate disciplines like mathematics, finance, and computer science, providing liquidity to the market through deep expertise and superior trading capabilities, enhancing accessibility and maintaining stability and efficiency in crypto markets.",
      },
      crypto: {
        title: "Crypto Investment",
        subtitle: "A new paradigm of wealth growth",
        content:
          "We excel in quantitative hedging trading, driving technological innovation and financial revolution, offering institutional-grade services and better investment outcomes to investors, collectively shaping the future of crypto finance.",
      },
    },
    cooperatorCarousel: {
      title: "Collaborating with Global Leading Enterprises",
    },
    exploration: {
      text: "Start here to explore the charm of the crypto market",
    },
  },

  product: {
    header: {
      title: "The Ultimate Partner in Digital Asset Management",
      content:
        "Top-tier trading dashboard, advanced strategies, high-quality execution, and deep liquidity aggregation provide white-glove service for professional traders to trade effortlessly in any market cycle.",
    },
    products: {
      trade: {
        title: "Trade",
        content: {
          aggregateTransaction: {
            subtitle: "Aggregation Trading",
            desc: "Connects major trading platforms, aggregates market depth, and executes complex trading plans on a single platform.",
          },
          engine: {
            subtitle: "Efficient Execution Engine",
            desc: "Swift execution, maintains low latency and high throughput, ensuring immediate and precise trading.",
          },
        },
      },
      aiTrade: {
        title: "AI Trading",
        content: {
          algorithmFrame: {
            subtitle: "Leading Algorithm Framework",
            desc: "Integrates research from financial engineering, mathematics, and AI to deploy best-in-class algorithmic trading frameworks.",
          },
          riskSmartManagement: {
            subtitle: "Smart Risk Management",
            desc: "Quantitative analysis of potential market risks, real-time strategy adjustments, ensuring secure trading.",
          },
          strategyOptimization: {
            subtitle: "Adaptive Strategy Optimization",
            desc: "AI systems rapidly identify opportunities and learn from real-time market data, adaptively optimizing strategies for consistent investment returns.",
          },
          solvingStrategy: {
            subtitle: "Advanced Quantitative Strategies",
            desc: "AI technology supports decision-making for quantitative strategies. Market-making strategies leverage AI to provide liquidity, maximize returns, and effectively manage risks.",
          },
        },
      },
      accountManagement: {
        title: "Unified Account Management",
        content: {
          assetManagement: {
            key: "account-1",
            subtitle: "Integrated Fund Management",
            desc: "Cross-platform asset management and settlement, simplifying fund management processes.",
          },
          maximizeEfficiency: {
            subtitle: "Maximizing Fund Efficiency",
            desc: "Efficient fund scheduling to optimize and utilize funds effectively.",
          },
        },
      },
      dataAnalysis: {
        title: "Data Analysis",
        content: {
          marketInsight: {
            subtitle: "In-depth Market Insights",
            desc: "Deep data mining and analysis, generating market insight reports to assist decision-making.",
          },
          transactionDataAnalysis: {
            subtitle: "Trading Data Analysis",
            desc: "Investment reports and feedback, including investment return analysis and execution quality evaluation, comprehensively monitoring financial status and portfolio performance.",
          },
        },
      },
    },
    startup: {
      header: {
        title:
          "Digital Asset Management Platform Designed for Professional Investors",
        content:
          "We've gathered the brightest minds from various disciplines to create everything needed to explore digital asset management and achieve our ambitions together.",
      },
      list: {
        quantitativeTrading: "AI Quantitative Trading",
        deepLiquidity: "Deep Liquidity",
        customizableStrategy: "Customizable Strategies",
        investmentAnalysis: "Data and Investment Analysis",
      },
      button: {
        text: "Want to break through investment boundaries? Start here.",
      },
    },
  },
  marketMaker: {
    banner: {
      title: {
        part1: "Market Maker ",
        part2: "injecting Vital Liquidity into Market",
      },
      content:
        "Liquidity is a critical metric for assets to trade quickly without causing price fluctuations.",
    },
    intro: {
      title: "About Market Maker",
      content:
        "Market makers act as the 'central nervous system' of markets. They respond quickly to market signals, coordinate buy and sell orders, ensure timely information dissemination, and smooth execution of trades, thereby creating liquidity in the market. Market makers are crucial for market stability and efficiency by stabilizing prices and providing immediate trading opportunities.",
    },
    style: {
      title: "TradingX's Market Making Style",
      content:
        "As a market maker in the crypto market, TradingX reduces the costs of capital allocation and market innovation, injecting vital liquidity into markets to provide seamless trading experiences for global traders, investment institutions, and market participants.",
      styles: {
        flow: {
          subtitle: "Liquidity",
          content:
            "Collaborating deeply with the world's leading exchanges to provide stronger liquidity.",
        },
        dataAnalysis: {
          subtitle: "Data Analysis",
          content:
            "Powerful and reliable databases generate intelligent reports promptly, enabling faster and more accurate decision-making.",
        },
        algorithmTrade: {
          subtitle: "Market-making Algorithm",
          content:
            "The market-making algorithm is based on the Avelaneda & Stoikov (AS) model and incorporates the latest AI technology, providing the market with an efficient and accurate liquidity solution.",
        },
        experience: {
          subtitle: "Experience",
          content:
            "Familiarity with project and exchange trading ecosystem requirements, offering customized services.",
        },
      },
    },
    tip: "Liquidity is crucial for exchanges. Order books with low liquidity often have large spreads and volatility, making it difficult for buyers and sellers to trade at appropriate prices. Ensuring asset liquidity allows both sides to trade quickly at any time.",
    contact: {
      title: "If You Need",
      lyrics: {
        enhanceLiquidity: {
          title: "Enhance Liquidity",
          content: "Reduce bid-ask spreads and improve trading efficiency.",
        },
        stabilizePrice: {
          title: "Stabilize Price",
          content:
            "Mitigate price fluctuations and maintain stability in the trading ecosystem.",
        },
        manageCostsNRisks: {
          title: "Manage Costs and Risks",
          content:
            "Precise entry and exit points to minimize trading slippage.",
        },
        institutionalLevelServices: {
          title: "Institutional-Level Services",
          content: "Support large-scale trading demands.",
        },
        solution: {
          title: "Solutions",
          content:
            "Liquidity strategies, tokenomics, crypto investment consulting, etc.",
        },
      },
    },
    joinUs: {
      content:
        "Need a reliable partner? Our team is ready to assist you anytime!",
      button: {
        text: "Contact Us",
      },
    },
  },
  digitalAssetManagement: {
    banner: {
      title: "Digital Asset Management",
      content:
        "Leading AI algorithmic trading system and robust liquidity ensure consistent and stable investment returns.",
    },
    management: {
      title: "Asset Management",
      content: {
        part1:
          "Utilize our validated algorithms to achieve investment returns in any market environment.",
        part2:
          "Our services and support enable more investors to effectively participate in digital asset investments.",
      },
      AMIs: {
        am1: {
          subtitle: "Liquidity and Market Access",
          content:
            "Extensive market access and efficient liquidity management ensure optimal asset allocation globally.",
        },
        am2: {
          subtitle: "AI-Enhanced Strategies",
          content:
            "We enhance strategy capabilities through AI, continuously optimize trading models for advanced insights and execution.",
        },
        am3: {
          subtitle: "Robust Investment Performance",
          content:
            "With advanced quantitative trading strategies and execution, we achieve investment returns in any market environment.",
        },
        am4: {
          subtitle: "Risk Management and Compliance",
          content:
            "Our comprehensive risk management framework integrates compliance standards to ensure transparency and asset security.",
        },
        am5: {
          subtitle: "Third-Party Custody",
          content:
            "Support of third-party custody services to mitigate counterparty risks.",
        },
        am6: {
          subtitle: "Customized Investment Solutions",
          content:
            "We offer personalized investment solutions based on client goals and risk preferences for tailored asset management.",
        },
      },
    },
    tip: "The crypto market is known for its violent price swings, complex technology, and ever-changing regulatory environment, which together form a highly volatile and intricate investment field. Posing challenges for investors to identify and evaluate genuine investment opportunities.",
    issue: {
      title: "Challenges Faced by Investors",
      content: {
        c1: "Digital asset liquidity is scattered, with limited market depth accessible from a single exchange.",
        c2: "Opaque market depth makes it difficult to discern the authenticity of market data and reports, leading to investment errors.",
        c3: "Low execution quality complicates handling large trades, increasing risks such as high slippage.",
        c4: "Emotion-driven decision-making during market volatility leads to irrational decisions.",
      },
    },
    sciManagement: {
      title: "Scientific Digital Asset Management",
      content:
        "We apply scientific methods to analyze the digital asset market, strategically invest based on precise trading models, and achieve stable investment returns even in bear markets, fostering continuous wealth growth for clients.",
      SMs: {
        sm1: {
          subtitle: "Algorithm Execution",
          content:
            "Automatically follow trading rules, facilitate high-quality execution, reducing human errors.",
        },
        sm2: {
          subtitle: "Trading Systems",
          content:
            "Our systems ensure low-latency, high-throughput global trading, ensuring excellent trading performance.",
        },
        sm3: {
          subtitle: "Data Network",
          content:
            "Identify complex markets, reliable basis for investment decisions.",
        },
        sm4: {
          subtitle: "Diversified Strategies",
          content:
            "Factor research is our expertise, combining AI to form predictable, autonomously optimized diversified strategies.",
        },
        sm5: {
          subtitle: "Risk Management",
          content:
            "Comprehensive risk control system monitors strategy performance in real-time, ensuring stable investment execution.",
        },
        sm6: {
          subtitle: "White-Glove Service",
          content:
            "24x7 institutional-level white-glove service provides clients with a secure digital asset investment experience.",
        },
      },
    },
    contact: {
      text: "If you need a trusted partner, please contact us.",
      button: {
        text: "Contact Us",
      },
    },
  },
  advisory: {
    banner: {
      title: "Advisory Services",
      content1: "Finding elegant solutions to complex problems",
      content2:
        "TradingX offers in-depth and comprehensive advisory services, leveraging rich expertise, industry insights, and advanced technology to effectively navigate market dynamics and create value for clients. Our advisory services primarily target financial institutions, projects, and high-net-worth individuals.",
    },
    advisory: {
      AIs: {
        FI: {
          subtitle: "Financial Institutions",
          subContent:
            "We understand the challenges financial institutions face in exploring the crypto industry: market complexity, future uncertainty, and interdisciplinary knowledge demands. With in-depth experience in traditional financial and a multidisciplinary approach to innovation, we tailor personalized solutions to help financial institutions accurately identify risks, capture opportunities, and achieve robust asset growth and long-term success in the unpredictable crypto market.",
          subItems: {
            fi1: {
              name: "Entry into Crypto Markets",
            },
            fi2: {
              name: "Investment Strategy Advisory",
            },
            fi3: {
              name: "Algorithmic Trading",
            },
            fi4: {
              name: "FinTech Research",
            },
            fi5: {
              name: "Risk Management",
            },
            fi6: {
              name: "Liquidity Management",
            },
          },
        },
        project: {
          subtitle: "Projects",
          subContent:
            "We provide comprehensive consulting and customized solutions for projects to address challenges in token issuance and market lifecycle management. Leveraging our technology and deep liquidity to achieve higher market penetration and risk management. We collaborate with ambitious projects planning to list on top-tier exchanges.",
          subItems: {
            p1: {
              name: "Liquidity Management",
            },
            p2: {
              name: "Tokenomics Advisory",
            },
            p3: {
              name: "Research and Analysis",
            },
            p4: {
              name: "Ecosystem Development",
            },
            p5: {
              name: "Risk Management",
            },
            p6: {
              name: "Financial Planning",
            },
          },
        },
        HNWI: {
          subtitle: "High-Net-Worth Individuals",
          subContent:
            "Cryptocurrency investment holds immense potential but also comes with skepticism and challenges. Market opacity, technological complexity, and the lack of asset management professionalism often leave individual investors confused and uncertain. We specialize in addressing these concerns, reducing barriers to crypto investments, and minimizing friction for investors.",
          subItems: {
            hnwi1: {
              name: "Market Insights and Analysis",
            },
            hnwi2: {
              name: "Customized Asset Management",
            },
            hnwi3: {
              name: "Algorithmic Trading",
            },
            hnwi4: {
              name: "FinTech Research",
            },
            hnwi5: {
              name: "Risk Management",
            },
            hnwi6: {
              name: "24/7 Dedicated Customer Service",
            },
          },
        },
      },
    },
    contact: {
      left: {
        h1: "Make TradingX an accelerator for driving the development of your digital asset strategy.",
        button: "Contact Us",
      },
      right: {
        h1: "Related Services",
        list: {
          l1: "TradingX",
          l2: "Market Maker",
          l3: "Asset Management",
        },
      },
    },
  },
  aboutUs: {
    banner: {
      left: {
        title: "About TradingX",
        content1:
          "TradingX is a leading algorithmic trading firm that enhances market seamlessness and accessibility through algorithms, expanding the boundaries of finance. As a global liquidity provider, we continuously inject vitality into the market, driving efficiency, transparency, and innovation in the crypto market.",
        content2:
          "Our members focus on technology development and financial innovation. We’re dynamic problem solvers. We believe in the future of crypto and maintain patience and creativity.",
      },
      right: {
        stats: {
          stat1: {
            name: "Trading Volume",
          },
          stat2: {
            name: "Scientific Basis",
          },
          stat3: {
            name: "Clients",
          },
          stat4: {
            name: "Customer Service",
          },
        },
      },
    },
    duty: {
      title: "Our Mission",
      content1: "We’re a leading global algorithmic trading firm, ",
      content2:
        "Our mission is to continuously expand the boundaries of finance, empower market participants, and promote market transparency, fairness, and efficiency.",
    },
    values: {
      header: {
        title: "Our Values",
        content:
          "Our values bring together like-minded talents from around the globe, fostering close collaboration to create a leading algorithmic trading ecosystem, collectively promoting the prosperity of a decentralized world.",
      },
      valuesItems: {
        v1: {
          title: "Integrity",
          content:
            "We adhere to the highest ethical and legal standards as guidelines for behavior, maintaining sincerity and responsibility.",
        },
        v2: {
          title: "Precision",
          content:
            "We uphold a precise and meticulous attitude, striving for high standards and quality in research, decision-making, and execution processes.",
        },
        v3: {
          title: "Openness",
          content:
            "We foster an open work environment, advocating for collisions of diverse thoughts and interdisciplinary exchanges to build an outstanding team.",
        },
        v4: {
          title: "Innovation",
          content:
            "We dare to break boundaries, explore the unknown, and continuously transcend ourselves through innovation to drive industry progress.",
        },
        v5: {
          title: "Winning",
          content:
            "We constantly improve and increase our winning rate, defeating opponents in market competition. We explore new strategies to help clients overcome challenges.",
        },
        v6: {
          title: "Mutuality",
          content:
            "We establish long-term cooperative relationships of mutual benefit with clients, partners, and members, sharing growth and success.",
        },
      },
    },
    ourTeam: {
      header: {
        title: "Integrated Team",
        content:
          "Our team consists of top talents in financial, mathematical sciences, engineering, and other fields, driving financial technology innovation and achieving our common mission.",
      },
      ourTeamItems: {
        ot1: {
          title: "AI Research",
          content:
            "Our expert team is committed to AI technology research to achieve self-optimization of trading algorithms and market adaptability.",
        },
        ot2: {
          title: "Engineering",
          content:
            "Our engineering team focuses on building scalable, highly reliable infrastructure to ensure system availability and low latency, meeting the demands of large-scale trading.",
        },
        ot3: {
          title: "Market & Trading",
          content:
            "The market and trading team, with their profound insights into the market, execute precise trading operations while managing and hedging market risks to achieve a robust trading performance.",
        },
        ot4: {
          title: "Quantitative Research",
          content:
            "The quantitative research team excels in mathematical models and statistics, discovering subtle connections in volatile markets and constructing complex algorithms driving trades.",
        },
        ot5: {
          title: "Business Consulting",
          content:
            "Our business consulting team provides customized solutions, helping clients understand markets, formulate strategies, and achieve business goals.",
        },
      },
    },
    solve: {
      header: {
        title: "What We Solve",
        content: "We solve complex market problems through our expertise.",
      },
      solveArounds: {
        sa1: {
          name: "Research & Deployment",
        },
        sa2: {
          name: "Risk Management",
        },
        sa3: {
          name: "Investment Research & Analysis",
        },
        sa4: {
          name: "Digital Asset Management",
        },
        sa5: {
          name: "Tokenomics Advisory",
        },
        sa6: {
          name: "Liquidity Management",
        },
        sa7: {
          name: "Ecosystem Advisory",
        },
        sa8: {
          name: "Customized Services",
        },
      },
    },
  },
  aboutCareer: {
    banner: {
      h1: "Career Opportunities",
      h2: "Craft Iterative Life Strategies to Realize Your Ambitions.",
      p1: {
        part1:
          "We gather talent from diverse backgrounds to build a free, diverse team.",
        part2:
          "Achieving our ambitions through exploring the unknown and tackling challenges that opponents cannot solve.",
      },
      p2: "We are ambitious and passionate about the crypto industry. If you are too, apply now!",
    },
    job: {
      header: {
        title: "Open Positions",
      },
      jobs: {
        quantitativeResearcher: {
          name: "Quantitative Researcher",
        },
        engineer: {
          name: "Senior Engineer",
        },
        trader: {
          name: "Trader",
        },
        applyButton: {
          text: "Apply",
        },
      },
    },
    careers: {
      quantitativeResearcher: {
        name: "Quantitative Researcher",
        remote: {
          location: "Hong Kong",
          mode: "Full-time",
        },
        applyButton: {
          text: "Apply Now",
        },
        finding: {
          f1: "We are looking for individuals who are passionate about mathematics and excel in developing automated trading algorithms. You should have a talent for creation and backtesting, excel in generating Alpha and creating automated strategies. You will work closely with traders and developers to propose hypotheses, collect and analyze data to develop new algorithms.",
          f2: "TradingX is looking for Senior Quantitative Researchers, this is a rare opportunity to join a leading global algorithmic trading firm. We value talent with diverse backgrounds and creativity, encouraging them to fully unleash their potential. At TradingX, you will have the chance to solve meaningful problems alongside geniuses from various fields and become an expert in cutting-edge domains. We strive to care for our employees by providing competitive incentives, benefits, and rewards.",
        },
        candidate: {
          title: "Our Ideal Candidate:",
          needs: {
            n1: {
              content:
                "Over 2 years of experience in quantitative research, especially in market making pricing and hedging strategies.",
            },
            n2: {
              content:
                "Master's degree or above in econometrics, mathematics, physics, statistics, computer science, or related disciplines.",
            },
            n3: {
              content:
                "Deep understanding of the market microstructure of digital assets, proficiency in at least one programming language such as Java, Python, or C++.",
            },
            n4: {
              content:
                "Experience in low-latency trading systems, strong mathematical and statistical abilities.",
            },
            n5: {
              content:
                "Excellent logical thinking and problem-solving skills, ability to work under pressure and take responsibility.",
            },
            n6: {
              content:
                "Competitive, continuously learning, proactive, good at collaboration, possess excellent communication skills. Proficiency in English is a plus.",
            },
          },
        },
        todos: {
          title: "What You Will Do:",
          td1: {
            content:
              "Collaborate with traders and the engineering team to optimize and innovate trading strategies.",
          },
          td2: {
            content:
              "Conduct in-depth research and analysis of data, identify and utilize market patterns.",
          },
          td3: {
            content:
              "Design and implement complex quantitative models and automated trading algorithms.",
          },
        },
      },
      engineer: {
        name: "Senior Engineer",
        remote: {
          location: "Hong Kong",
          mode: "Full-time",
        },
        applyButton: {
          text: "Apply Now",
        },
        finding: {
          f1: "Our engineers collaborate with researchers and traders to solve large-scale complex problems. Our team can quickly implement ideas, and the market provides rapid feedback.",
          f2: "TradingX is looking for Senior Engineers, this is a rare opportunity to join a global leader in algorithmic trading. We value talent with diverse backgrounds and creativity, encouraging them to fully unleash their potential. At TradingX, you will have the chance to solve meaningful problems alongside professionals from various fields and become an expert in cutting-edge domains. We strive to care for our employees by providing competitive incentives, benefits, and rewards.",
        },
        candidate: {
          title: "Our Ideal Candidate:",
          needs: {
            n1: {
              content:
                "Master's degree in computer science, engineering, statistics, mathematics, physics, or related fields.",
            },
            n2: {
              content:
                "Excellent design and programming skills, ability to work with multiple programming languages.",
            },
            n3: {
              content:
                "Ability to assist and effectively communicate in a complex and highly technical team environment.",
            },
            n4: {
              content:
                "Curiosity and enthusiasm for solving challenging problems using technology.",
            },
            n5: {
              content:
                "Strong analytical skills, familiarity with probability and statistics.",
            },
            n6: {
              content:
                "Experience in fintech companies, ability to conduct blockchain development.",
            },
          },
        },
        todos: {
          title: "What You Will Do:",
          td1: {
            content:
              "Design and develop financial market products, including low-latency algorithmic trading platforms.",
          },
          td2: {
            content: "Implement, verify, and execute software testing.",
          },
          td3: {
            content:
              "Support researchers and traders to ensure algorithm implementation.",
          },
          td4: {
            content:
              "Create and maintain documentation, write functional and design specifications.",
          },
          td5: {
            content: "Customize and improve systems according to requirements.",
          },
          td6: {
            content:
              "Code development and debugging, including relevant automated units and integration testing.",
          },
        },
      },
      trader: {
        name: "Trader",
        remote: {
          location: "Hong Kong",
          mode: "Full-time",
        },
        applyButton: {
          text: "Apply Now",
        },
        finding: {
          f1: "Our traders collaborate with researchers and engineers to implement great ideas and achieve better results.",
          f2: "TradingX is looking for Traders, this is a rare opportunity to join a global leader in algorithmic trading. We value talent with diverse backgrounds and creativity, encouraging them to fully unleash their potential. At TradingX, you will have the chance to solve meaningful problems alongside geniuses from various fields and become an expert in cutting-edge domains. We strive to care for our employees by providing competitive incentives, benefits, and rewards.",
        },
        candidate: {
          title: "Our Ideal Candidate:",
          needs: {
            n1: {
              content:
                "Master's degree in finance, economics, mathematics, statistics, or related fields.",
            },
            n2: {
              content:
                "At least 2 years of experience in trading in the crypto industry.",
            },
            n3: {
              content:
                "Extensive understanding of financial markets and trading strategies, especially market making.",
            },
            n4: {
              content:
                "Strong analytical, quantitative, and interpersonal skills.",
            },
            n5: {
              content:
                "Ability to assess risks and opportunities related to new tokens.",
            },
            n6: {
              content:
                "Ability to manage complex trades and positions, stay updated on crypto market trends and regulatory changes.",
            },
            n7: {
              content:
                "Competitive, continuously learning, proactive, good at collaboration.",
            },
            n8: {
              content:
                "Excellent communication skills, proficiency in English is a plus.",
            },
          },
        },
        todos: {
          title: "What You Will Do:",
          td1: {
            content:
              "Possess extensive knowledge of digital asset trading and experience in trading on global exchanges.",
          },
          td2: {
            content:
              "Collaborate with ICO issuers to facilitate primary market trading and ensure timely and efficient execution.",
          },
          td3: {
            content:
              "Identify potential market making opportunities and optimize strategies.",
          },
          td4: {
            content:
              "Provide insights into the market, formulate plans, identify risks, and execute complex trades on a large scale.",
          },
          td5: {
            content:
              "Enhance the overall profitability of our digital asset business.",
          },
        },
      },
      application: {
        title: "Fill out Application Form",
        applyForm: {
          candidateInfo: {
            h1: "Candidate Information",
            name: {
              label: "Name",
              placeholder: "How should we address you",
            },
            resume: {
              label: "Resume",
              placeholder: "Upload your resume (.pdf, .doc)",
            },
            phone: {
              label: "Phone",
              placeholder: "Your mobile number",
            },
            email: {
              label: "Email",
              placeholder: "Your email address",
            },
            currentCompany: {
              label: "Current Company",
              placeholder: "Your company name",
            },
          },
          candidateLinks: {
            h1: "Links",
            linkedIn: {
              label: "LinkedIn URL",
              placeholder: "Your LinkedIn URL",
            },
            github: {
              label: "Github URL",
              placeholder: "Your Github URL",
            },
            combine: {
              label: "Combined URL",
              placeholder: "Your combined URL",
            },
            other: {
              label: "Other URL",
              placeholder: "Your other URL",
            },
          },
          candidateExtra: {
            h1: "Additional Information",
            p: "Add cover letter or any other content you want to share",
          },
          submitButton: {
            text: "Submit",
          },
          notification: {
            submit: {
              success: {
                message: "Submission successful",
                description:
                  "Your application form has been successfully submitted!",
              },
              error: {
                message: "Submission failed",
                description:
                  "Failed to submit application form. Please contact the administrator.",
              },
            },
          },
          Rules: {
            ResumeRule: {
              IsNotNull: "Resume/CV cannot be empty",
              IsOversize: "Resume file is too large, limit is 10MB",
            },
          },
        },
      },
    },
  },
  contactUs: {
    banner: {
      text: "Contact TradingX",
    },
    contact: {
      h1: "Please complete the provided form to contact with us.",
      formItems: {
        firstName: {
          placeholder: "First Name*",
        },
        lastName: {
          placeholder: "Last Name*",
        },
        email: {
          placeholder: "Email*",
        },
        telegram: {
          placeholder: "Telegram*",
        },
        company: {
          placeholder: "Company*",
        },
        position: {
          placeholder: "Position",
        },
        interest: {
          placeholder: "Interested In*",
          options: {
            opt1: {
              name: "TradingX",
            },
            opt2: {
              name: "Asset Management",
            },
            opt3: {
              name: "Market Maker",
            },
            opt4: {
              name: "Partnership Relations",
            },
          },
        },
        business: {
          placeholder: "Business*",
          options: {
            opt1: {
              name: "Hedge Fund",
            },
            opt2: {
              name: "Venture Fund",
            },
            opt3: {
              name: "Institutional Wealth Manager",
            },
            opt4: {
              name: "Financial Intermediary",
            },
            opt5: {
              name: "Proprietary Trader",
            },
            opt6: {
              name: "Financial Infrastructure Provider",
            },
            opt7: {
              name: "High Net Worth Individual",
            },
            opt8: {
              name: "Other",
            },
          },
        },
        assetRange: {
          placeholder: "AUM Range*",
          options: {
            opt1: {
              name: "<1M",
            },
            opt2: {
              name: "1M-10M",
            },
            opt3: {
              name: "10M-100M",
            },
            opt4: {
              name: "100M-500M",
            },
            opt5: {
              name: "500M-1B",
            },
            opt6: {
              name: ">1B",
            },
          },
        },
        country: {
          placeholder: "Country*",
        },
        submitButton: {
          text: "Submit",
        },
      },
    },
  },
  footer: {
    media: {
      title: "Social Media",
    },
    production: {
      title: "Products",
      tradingx: "TradingX",
    },
    about: {
      title: "About",
      aboutUs: "About Us",
      career: "Career",
    },
    service: {
      title: "Services",
      marketMaker: "Market Making",
      digitalAssetManagement: "Digital Asset Management",
      consultationService: "Advisory",
      serviceObject: "",
    },
    contact: {
      title: "Contact Us",
      contact: "Contact",
    },
  },

  sider: {
    trade: "Trade",
    account: "Account",
    AITrade: "AI Trade",
    TradeData: "Trade Data",
    data: "Data",
    Settings: "Settings",
  },
  dashboardTrade: {
    PleaseSelectPosition: "Please select position",
    AllClosePosition: "Selected one-click close position",
    SyncMode: "Sync Mode",
    Cont: "Cont",
    IsHideMin: "Hide Small Assets",
    Asset: "Asset",
    Query: "Search",
    OpenLong: "Open Long",
    CloseShort: "Close Short",
    open: "Open",
    close: "Close",
    Spot: "Spot",
    Index: "Index",
    Futures: "Futures",
    Forex: "Forex",
    AStock: "A Stock",
    AStock_SH: "A Stock(SH)",
    AStock_SZ: "A Stock(SZ)",
    CloseLong: "Close Long",
    OpenShort: "Open Short",
    USDFutures: "USDⓈ-M Futures",
    COINFutures: "COIN-M Futures",
    Perpetual: "Perpetual",
    Quarterly: "Quarterly",
    Currently: "Quarterly",
    DayChange: "Change",
    DayHigh: "High",
    DayLow: "Low",
    DayVolumeone: "Volume",
    DayVolumetwo: "Amount",
    Day: "Day ",
    Shares: "Shares",
    Price: "Price",
    Amount: "Amount",
    Total: "Total",
    Buy: "Buy",
    Sell: "Sell",
    Limit: "Limit",
    MaxBuy: "Max Buy",
    Available: "Avbl",
    Balance: "Balance",
    AcceptQuotesSpread: "Accept Spread",
    QuickMode: "Quick Mode",
    Market: "Market",
    LogIn: "Log In",
    TriggerOrder: "TP/SL",
    TriggerPrice: "Trigger Price",
    Tradeatthebestprice: "Execute at the best price",
    ActivateAccount: "Activate Account",
    SwitchMainAccount: "Switch Main Account",
    Markets: "Markets",
    Depth: "Depth",
    OpenOrders: "Open Orders",
    SubAccount: "Sub-account",
    Pair: "Pair",
    Time1: "Time",
    Side: "Side",
    Amount1: "Amount",
    Total1: "Total",
    FilledAmount: "Filled Amount",
    TotalFilled: "Total Filled",
    AveragePrice: "Avg Price",
    ExecutionTime: "Time",
    Status: "Status",
    CancelAllOrder: "Cancel All",
    OrderStatus: "Status",
    TimeInForce: "Validity",
    ImpactPercent: "IMP",
    OrderType: "Type",
    OrderQty: "Quantity",
    PositionSide: "Direction",
    TradeRate: "Filled",
    Action: "Action",
    OrderHistory: "Order History",
    PositionHistory: "Close History",
    AveragePositionCost: "Avg Position Cost",
    AverageClosePrice: "Avg Close Price",
    CloseAmount: "Close Amount",
    RealizedPnL: "Realized PNL(USD)",

    Position: "Position",
    Typenew: "Type",
    OpenPositions: "Open Positions",
    AverageOpenPrice: "Avg Open Price ",
    MarkPrice: "Mark Price",
    Margin: "Margin",
    MarginRatio: "Margin Ratio",
    UnrealizedPnL: "Unrealized PNL",
    ExchangePosition: "Exchange Position",
    ExchangeOpenOrder: "Exchange Pending Order",
    OpenOrder: "Pending Order",
    LiqPrice: "Liq. Price",
    Time: "Time",
    Type: "Type",
    CurrentTokensOnly: "Hide Other Coins",
    TriggerOrderLimit: "Trigger Order Limit",
    TriggerOrderMarket: "Trigger Order Market",
    BaseVersion: "Exclusive",
    ProVersion: "Pro",
    Details: "Details",
    Revoke: "Revoke",
    NineRounds: {
      Name: "Nine-Turn",
      NineRoundsA: {
        Name: "Nine-Turn A",
        TwoPointMethod: "Two-dot Method",
        ThreePointMethod: "Three-dot Method",
      },
      NineRoundsD: {
        D1Name: "Nine-Turn D1",
        D2Name: "Nine-Turn D2",
        SpaceMethod: "Space Method",
        TimeMethod: "Time Method",
      },
      Delete: "Delete",
      MessageText: {
        RenderError:
          "Unable to draw the Nine-Turn, please switch time or redraw.",
        Open: "Open",
        High: "High",
        Low: "Low",
        Close: "Close",
        Volume: "Volume",
        FirstPoint: "First Dot",
        SecondPoint: "Second Dot",
        ThirdPoint: "Third Dot",
        NewestPrice: "Last Price",
        RenderTipsClickTwo: "Click 2 dots.",
        RenderTipsClickThree: "Click 3 dots.",
        ConfirmRender: "Confirm to draw?",
        ConfirmReRender: "Confirm to redraw?",
        WillClear: "Clear Nine-Turn",
        Time: "Time",
        Price: "Price",
      },
    },
    Layout: {
      Name: "Layout",
      Lock: "Move Layout",
      AutoSave: "Auto Save",
      Rename: "Rename",
      NewLayout: "New Layout",
      MyLayout: "My Layout",
      RecoverDefault: "Restore Default",
      ConfirmDelete: "Confirm to delete the layout?",
      ConfirmDeleteTip: "Once deleted it cannot be restored.",
      Cancel: "Cancel",
      Confirm: "Confirm",
      SubMap: "Sub Map",
      BrowserNotSupport:
        "The current user's browser does not support Canvas, please change the browser and try again!",
      SaveSuccess: "Save Successful",
      SaveFail: "Save Failed",
      DeleteSuccess: "Delete Successful",
      DeleteFail: "Delete Failed",
    },
    RuleMessageTest: {
      NumRule: {
        IsNotNum: "Imput a number",
        IsIllegalNum: "Imput a valid number",
        IsNotNull: "Cannot be empty",
      },
      EmailRule: {
        IsIllegalEmail: "Please enter the correct email address",
        IsNotNull: "Email address cannot be empty",
      },
      PhoneRule: {
        IsNotNull: "Phone number cannot be empty",
        IsIllegalPhoneNumber: "Please enter a valid phone number",
      },
    },
    Deribit: {
      Position: "Position",
      OpenOrder: "Open Orders",
      OrderHistory: "Order History",
      PositionHistory: "Position History",
    },
  },
  views_login: {
    login: "Log In",
    login_input_userName_placeholder: "Email/Phone",
    login_input_password_placeholder: "Password",
    login_input_VerificationCode_placeholder: "Verification Code",
    login_remember_password: "Remember password",
    login_forget_password: "Forgot password?",
    reset: "Reset",
    reset_password: "Reset Password",
    login_register: "Register",
  },
  client_things: {
    TotalVolume: "Total Volume",
    WeekVolume: "7d Volume",
    TotalMMVolume: "Total MM Volume",
    OrderQuantity: "Order Quantity",
    CommisionSaving: "Commision Saving",
    TotalRebate: "Total Rebate",
    TotalSaving: "Total Saving",
    TradeNow: "Trade Now",
    client_present: "Trade Easier",
    client_present_long: "Digital Assets Investment Management Platform",
    standard: "BTC spot has obtained SEC standard",
    standardMore: "More",
    WhyTradingX: "Why TradingX?",
    Executeinaggregation: "Efficient Aggregated Execution",
    Leadingmarketmakingtools: "Leading Market Making Tools",
    DataAnalysis: "Data Analysis",
    ComplianceSecurity: "Compliance&Security",
    Executeinaggregationnew:
      "Execute trading plans on major exchanges with a click on a single interface; reduce dealings with exchanges.",
    Leadingmarketmakingtoolsnew:
      "Fully auto trading bots and semi-auto tools help investors win in any market",
    DataAnalysisnew:
      "Analyse datas on the mainstream chains, trading datas of DEXes and CEXes, global stock market, foreign exchange market, gold and other trading datas. Help to optimize portfolios.",
    ComplianceSecuritynew:
      "Singapore and Hong Kong licenses, make trading more secure.",
    Announcement: "Announcement",
    UserGuide: "User Guide",
    ContactJoinUs: "Contact/Join Us",
  },
  components_myheader: {
    loginout: "Log Out",
    login: "Log In",
    register: "Register",
    totalone: "24h Volume",
    totaltwo: "24h AI Volume",
    totalthree: "24h Bot Volume",
    totalfour: "24h Rebate Amount",
    totalfive: "24h MM Volume",
    referral: "Referral",
    AllRead: "All Read",
    NoMore: "No More",
    TradeMessage: "Trade Message",
    Announcement: "Announcement",
    SystemMessage: "System Message",
    loadMore: "load More",
  },
  components_mysymbolselect: {
    search: "Search",
  },
  views_signup: {
    email: "Email",
    phone: "Phone",
    signup_input_userName: "Username",
    signup_input_userName_placeholder_email: "Email Address",
    signup_input_userName_placeholder_phone: "Phone Number",
    signup_input_userName_placeholder_Password: "Password",
    signup_input_userName_placeholder_confirmPassword: "Confirm your password",
    signup_input_userName_placeholder_inviteCode: "Referral code",
    signup_input_userName_placeholder_VerificationCode: "Verification Code",
    signup_input_userName_placeholder_Code: "Get a code",
    signup_register: "Register",
    signup_login: "Log in with registered account",
    signup_signup_success: "Registration Successful.",
    signup_email_phone: "Email/Telephone",
    signup_back_login: "Back to Log In",
    signup_please_input_password: "Imput the password",
    signup_please_input_size_password: "Imput a password of 8-20 digits",
    signup_please_input_confirm_password: "Confirm the Password",
  },
  user_info: {
    AccountManagement: "Account Management",
    Phone: "Phone",
    Level: "Level",
    Type: "Type",
    LoginLog: "Login Log",
    Add: "Add",
    Exchange: "Exchange",
    AccountName: "Account Name",
    APIKEY: "API Key",
    Secretkey: " Secret Key",
    ExpirationTime: "Expiration Time",
    PermissionList: "Permission List",
    IPList: "IP List",
    CreateTime: "Create Time",
    UpdateTime: "Update Time",
    Action: "Action",
    ViewBalance: "View Balance",
    Edit: "Edit",
    Delete: "Delete",

    AssetsManagement: "Assets Analysis",
    FinancialAnalysis: "Financial Analysis",

    StrategyAnalysis: "Strategy Analysis",
    SelectExchange: "Select Exchange",
    SelectStrategyType: "Select Strategy Type",
    SumQty: "Total Transaction Amount",
    TotalSumQty: "Cumulative Transaction Amount",
    SumCost24H: "24H Funding Cost",
    SumQty24H: "24H Transaction Amount",
    MaxHold24H: "24H Maximum Position",
    MaxHoldLong24H: "24H Maximum Position (Long)",
    MaxHoldShort24H: "24H Maximum Position (Short)",
    SumNetProfit24H: "24H Net Profit",
    ProfitAndMaxDrawDownChart: "Profit and Maximum Drawdown",
    NetProfitRate24H: "24H Rate of Return",
    SumNetProfitRate: "Cumulative Rate of Return",
    LowWater: "Low Water Level",
    MaxDrawDown24H: "24H Maximum Drawdown",

    NetValueChart: "Compound rate of return",
    DrawDownChart: "Drawdown",
    ProfitChart: "Profit",
    NetValue: "Net Asset Value",
    HighWater: "High Water",
    DrawDown: "Drawdown",
    Profit: "Profit",
    TotalProfit: "Cumulative profit",

    Date: "Time",
    Return: "Yield",
    GrossReturn: "Gross Yield",
    Nav: "Nav",
    Balance: "Balance",
    Transfer: "Transfer",
    LastUpdateTime: "Last update time",

    AnnualReturn: "Annualized rate of return",
    AnnualVolatility: "Annualized Volatility",
    SharpeRatio: "Sharpe Ratio",
    MaxDrawDown: "Maximum drawdown",

    Hour: "hour",
    Day: "Day",
    Week: "week",
    Month: "month",
    Year: "year",
    TIME_7D: "7 days",
    TIME_30D: "30 days",
    TIME_90D: "90 days",
    TIME_180D: "half a year",
    TIME_365D: "One year",
    OTHER: "Custom",

    SelectAccountType: "Select account type",
    SelectTimeRange: "Select time range",
    SelectTimeDuration: "Select time interval",
    SelectSubAccount: "Select subaccount",
    SelectStatisticsCurrency: "Select statistical currency",

    LastDayPnL: "24h PNL",
    LastWeekPnL: "7 days PNL",
    LastMonthPnL: "30 days PNL",
    Chart: "Chart",
    DailyPnL: "Daily PNL",
    CumulativePnL: "Cumulative PNL",
    PnLDistribution: "PNL Allocation",
    Smart: "AI Trade",
    Bots: "Bots",
    AssetsDistribution: "Assets Allocation",
    PositionDistribution: "Position Allocation",

    Traders: "Traders",
    Observer: "Observer",
    ToVerify: "Verify Now",
    ConfirmDelete: "Confirm deletion？",
    ConfirmDeleteTips: "This action cannot be undone！",
    Confirm: "Confirm",
    Cancel: "Cancel",

    AccountBalance: "Account Balance",
    Currency: "Coin",

    AccountType: "Type",
    AvailableBalance: "Avl.Balance",
    LockBalance: "Lock Balance",
    MarginBalance: "Margin Balance",
    WalletBalance: "Wallet Balance",
    MaxTransferableBalance: "Max Transferable Balance",
    Total: "Total",
    Record: "Records",

    Account: "Account",
    LoginIP: "Login IP",
    LoginTime: "Login Time",

    ApiKey: {
      ApiForm: {
        NewAccount: "New Account",
        EditAccount: "Edit Account",
        AccountName: "Account Name",
        Exchange: "Exchange",
        AccountApiKey: "API Key",
        AccountSecretKey: "Secret Key",
        AccountPassPhrase: "Passphrase",
        AuthList: "Auth List",
        SpotTrade: "Spot",
        FutureTrade: "Future",
        Reset: "Reset",
        Rule: {
          NameMsg: "Account Name",
          ExchangeMsg: "Exchange",
          ApiKeyMsg: "Api Key",
          SecretKeyMsg: "Secret Key",
          PassPhraseMsg: "Passphrase",
          AuthListMsg: "Select Permissions",
        },
        Confirm: "Confirm",
        Cancel: "Cancel",
      },
    },
  },
  data_info: {
    Data: "Data",
    Greedindex: "Greed index",
  },
  setting_info: {
    System: "System Settings",
    Language: "Language",
    Currency: "Currency",
    TimeZone: "Time Zone",
    Notice: "Notice",
    Trade: "Trade",
    OpenTradeNotice: "Open Trade Notice",
    Announcement: "Announcement",
    OpenAnnouncementNotice: "Open Announcement Notice",
    ON: "ON",
    OFF: "OFF",
    Security: "Security",
    BindEmail: "Bind Email",
    BindedEmai: "Binded Email",
    SMSVerification: "SMS Verification",
    EffectivelyProtectAccountSecurity: "Effectively Protect Account Security",
    EmailVerification: "Email Verification",
    EffectivelyProtectEmailSecurity: "Effectively Protect Email Security",
    BindIP: "Bind IP",
    BindedIP: "Binded IP",
    Change: "Change",
    ChangePassword: "Change Password",
    LanguageSetting: {
      SimplifiedChinese: "简体中文",
      TraditionalChinese: "繁體中文",
      English: "English",
    },
    SecuritySetting: {
      AccountPassword: "Account Password",
      PasswordLevel: "Password Level",
      PasswordLevelLow: "Low",
      PasswordLevelMedium: "Medium",
      PasswordLevelHigh: "High",
    },
    PlaceHolderText: {
      OldPassword: "Old Password",
      NewPassword: "New Password",
      ConfirmPassword: "Confirm Password",
    },
  },
  PlaceHolderText: {
    Cancel: "Cancel",
    Confirm: "Confirm",
  },

  Dictionary: {
    EXECUTING: "Executing",
    EXECUTED: "Executed",
    CANCELING: "Canceling",
    CANCELED: "Canceled",
    EXECUTION_ERROR: "Execution Error",
    OPEN: "Open",
    CLOSE: "Close",
    BUY: "Buy",
    SELL: "Sell",
    LONG: "Long",
    SHORT: "Short",
    BOTH: "Both",
    NORMAL_MODE: "Normal Mode",
    FILLED: "Filled",
    LIMIT: "Limit",
    MARKET: "Market",
    STOP_MARKET: "Stop Loss Market",
    TAKE_PROFIT_MARKET: "Take Profit Market",
    TAKE_PROFIT: "Take Profit",
    STOP_LOSS: "Stop Loss",
    PARTIALLY_FILLED: "Partially Filled",
    TRADING: "Trading",
    DIRECT_MODE: "Direct Mode",
    BINANCE_FUNDING: "Funds",
    BINANCE_SPOT: "Spot",
    BINANCE_MARGIN_CROSSED: "Full Margin",
    BINANCE_MARGIN_ISOLATED: "Isolated Margin",
    BINANCE_FUTURE: "USDⓈ-M Futures",
    BINANCE_SWAP: "Coin-M Futures",
    SPOT: "Spot",
    SWAP: "Coin-M Futures",
    FUTURE: "USDⓈ-M Futures",
    LINEAR: "USD Contract",
    INVERSE: "Inverse Contract",
    spot: "Spot",
    linear: "USD Contract",
    inverse: "Inverse Contract",
    OKX_SPOT: "Spot",
    OKX_SWAP: "Perpetual Contract",
    OKX_FUTURES: "Delivery Contract",
    OPTION: "Option",
    INDEX: "Index",
    FUTURES: "Futures",
    FOREX: "Forex",
    ASTOCK: "A Stock",
    EXECUTION_TIMEOUT: "Execution Timeout",
    UNFILLED: "Unfilled",
    TRIGGER_ORDER_LIMIT: "Trigger Limit",
    TRIGGER_ORDER_MARKET: "Trigger Market",
    WAITING: "Waiting",
    EXPIRED: "Expired",
    PLACE_ORDER: "Maker",
    FAILURE: "Failure",
    PREPARING: "Preparing",
    STOPPED: "Stopped",
    STOPPING: "Stopping",
    SELL_CLOSE: "Sell Close",
    SELL_OPEN: "Sell Open",
    BUY_CLOSE: "Buy Close",
    BUY_OPEN: "Buy Open",
    CLOSE_LONG: "Close Long",
    CLOSE_SHORT: "Close Short",
    INTERRUPTED: "Interrupted",

    UNIFIED: "UNIFIED",
    CONTRACT: "CONTRACT",

    SAT_BINANCE_SPOT: "Spot",
    SAT_BINANCE_MARGIN_CROSSED: "Cross Margin",
    SAT_BINANCE_MARGIN_ISOLATED: "Isolated Margin",
    SAT_BINANCE_FUTURE: "USDⓈ-M",
    SAT_BINANCE_SWAP: "Coin-M",
    SAT_OKX_SPOT: "Spot",
    SAT_OKX_SWAP: "Perpetual",
    SAT_OKX_FUTURES: "Delivery",
    SAT_BYBIT_spot: "Spot",
    SAT_BYBIT_linear: "USDⓈ-M",
    SAT_BYBIT_inverse: "Inverse",

    AAT_BINANCE_FUNDING: "Funding",
    AAT_BINANCE_SPOT: "Spot",
    AAT_BINANCE_MARGIN_CROSSED: "Cross Margin",
    AAT_BINANCE_MARGIN_ISOLATED: "Isolated Margin",
    AAT_BINANCE_FUTURE: "USDⓈ-M",
    AAT_BINANCE_SWAP: "Coin-M",
    AAT_OKX_FUNDING: "Funding",
    AAT_OKX_SPOT: "Unified",
    AAT_BYBIT_FUNDING: "Funding",
    AAT_BYBIT_UNIFIED: "Unified",
    AAT_BYBIT_CONTRACT: "Contract",
  },
  Modal: {
    EmptyModal: {
      Message: "No Data",
    },
    InviteModal: {
      Invite: "Invite",
      Cancel: "Cancel",
      RateOfReturn: "Yield",
      InviteCode: "Referral Code",
      Copy: "Copy",
    },

    DeribitModal: {
      Cancel: "Cancel",
      Call: "Long",
      Put: "Short",
      Amount: "Amount",
      Position: "Position",
      LimitPrice: "Limit Price",
      AdvancedOrderForm: "Advanced Order",
      TimeInForce: "TIF",
      Reduce: "Reduce",
      Post: "Post",
      BuyMargin: "Buy Margin",
      SellMargin: "Sell Margin",
      ContractDetails: "Contract Details",
      PriceSource: "Price Source",
      ContractSize: "Contract Size",
      MinOrderSize: "Min Order Size",
      TickSize: "Tick Size",
      SettlementCurrency: "Settlement Currency",
      ExpiryDate: "Expiry Date",
      MinSellPrice: "Min Sell Price",
      MaxBuyPrice: "Max Buy Price",
      Delta: "Delta",
      Gamma: "Gamma",
      Vega: "Vega",
      Theta: "Theta",
      Rho: "Rho",
      OrderBook: "Order Book",
      MarkPrice: "Mark Price",
      the24hLow: "24h Low",
      the24hHigh: "24h High",
      the24hVolume: "24h Volume",
      the24hChange: "24h Change",
      Total: "Total",
      BidSize: "Bid Size",
      BidPrice: "Bid Price",
      AskPrice: "Ask Price",
      AskSize: "Ask Size",
      RecentTrade: "Last Trades",
      Chart: "Chart",
      Buy: "Buy",
      Sell: "Sell",
    },
    WarningModal: {
      PriceAlert: "Price Alert",
      Monitoring: "Monitoring",
      MonitoringRecord: "Monitoring Record",
      NewAlert: "New Alert",
      Cancel: "Cancel",
      PriceAlertSettings: "Price Alert Settings",
      Save: "Save",
      Load: "Load",
      TransactionType: "Trade Type",
      PleaseSelect: "Please Select",
      NewestPrice: "Last Price",
      TriggerPrice: "Trigger Price",
      DownTo: "Down to",
      UpTo: "Up to",
      RangePrice: "Price Range",
      Amplitude: "Amplitude",
      WarningDuration: "Alert Duration",
      Alert: "Alert",
      ExpirationTime: "Expiration Time",
      OneMinute: "1 Minute",
      CloseManually: "Close Manually",
      WebSide: "Web Side",
      AppSide: "App Side",
      TriggeringConditions: "Trigger Conditions",
      PriceAtSetup: "Price At Setup",
      CreatedOrExpired: "Created/Expired",
      Status: "Status",
      Action: "Action",
      EffectiveTime: "Effective Time",
    },
    LeverModal: {
      LevelTransfer: "Leverage Transfer",
      Transfer: "Transfer",
      Cancel: "Cancel",
      From: "From",
      To: "To",
      Currency: "Coin",
      AvailableFunds: "Available Funds",
      TransferAmount: "Transfer Amount",
      All: "All",
      IsolateAccount: "Isolated",
      FullAccount: "Cross",
      SpotAccount: "Spot",
    },
    AIModal: {
      Spot: "Spot",
      Isolate: "Isolated",
      Full: "Cross",
      BrrowAndRepay: "Brrow/Repay",
      PleaseLogin: "Please Login！",
    },
    CiculateModal: {
      Spot: "Spot",
      Isolate: "Isolated",
      Full: "Cross",
      MarginBrrowAndRepay: "Margin Brrow/Repay",
      Confirm: "Confirm",
      Cancel: "Cancel",
      LoanAmount: "Loan Amount",
      CurrentlyAvailableToBorrowTips:
        "Max borrowable amount in the user account under sufficient available fund",
      CurrentlyAvailableToBorrow: "Currently available to borrow",
      MaxAvailableToBorrowTips:
        "Max borrowable amount for the current level as per platform limitations",
      MaxAvailableToBorrow: "Max Borrowable",
      HourlyInterestRate: "Hourly Interest Rate",
      HourlyInterestRateTips:
        "When borrowing assets, interest will immediately accrue for one hour, and interest will be calculated once per hour (less than one hour is calculated as one hour)",
      Loan: "Loan",
      Repay: "Repay",
      Available: "Available",
      Borrowed: "Borrowed",
      NetAssets: "Net Assets",
      CurrentInterest: "Current Interest",
      RepaymentAmount: "Repayment Amount",
      MaximumRepayable: "Max Repayable",
      OperationSuccessful: "Action Successful",
      OperationFailed: "Action Failed",
    },
    LeverageToolsModal: {
      Isolate: "Isolate",
      Full: "Cross",
      MarginModel: "Margin Mode",
      Confirm: "Confirm",
      MarginModelTips1:
        "Adjusting margin mode only applies to the current contract.)",
      FullMarginTipsTitle: "Cross Margin Mode:",
      FullMarginTipsContent:
        "Positions with the same margin assets in full margin mode share the full margin of that asset. During liquidation events, traders may lose the entire margin and all full margin positions associated with that margin asset.",
      IsolateMarginTipsTitle: "Isolated Margin Mode:",
      IsolateMarginTipsContent:
        "A certain amount of margin is allocated to each position. If the position's margin falls below the maintenance margin level, the position will be liquidated. In isolate mode, you can add or reduce margin for this position.",
      Cancel: "Cancel",
      LeverageSetting: "Leverage Setting",
      CurrentLeveragePosition: "Max",
      CurrentLeveragePositionTips1:
        "The leverage adjustment will affect both the current position and the leverage of Maker.",
      CurrentLeveragePositionTips2:
        "Trading with leverage exceeding [10x] increases the risk of forced liquidation. Please be aware of the associated risks.",
      ModelSetting: "Model Setting",
      PositionModel: "Position Mode",
      OneWayPosition: "One-way Mode",
      OneWayPositionTips:
        "In One-way Mode, a symbol only supports holding a position in one direction.",
      TwoWayPosition: "Hedge Mode",
      TwoWayPositionTips:
        "In Hedge Mode, a symbol supports simultaneously holding long position and short position, and unrealized PNL can be offset between two positions of the symbol.",
      AssetModel: "Asset Model",
      SingleModel: "Single-Asset Mode",
      SingleModelTips1:
        "Supports USDⓈ-M Futures trading by only using the single asset of the symbol.",
      SingleModelTips2: "PNL of the same margin asset positions can be offset.",
      SingleModelTips3: "Supports Cross Margin Mode and Isolated Margin Mode.",
      CombineModel: "Multi-Assets Mode",
      CombineModelTips1:
        "USDⓈ-M Futures trading across multiple margin assets.",
      CombineModelTips2:
        "PNL can be offset among the different margin asset positions.",
      CombineModelTips3: "Only supports Cross Margin Mode",
      PleaseSelectApi: "Please Set Api",
      ActionSuccessful: "Action Successful",
      ActionFailed: "Action Failed",
    },
    UserCountModal: {
      SelectSubAccount: "Sub Account",
      Save: "Save",
      Cancel: "Cancel",
      SelectExchange: "Exchange",
      All: "All",
      Main: "Main",
      Ratio: "Ratio",
      MaxQty: "Max Amount",
      MaxQtyTips: "Imput the Max Avbl amount",
      SyncRatio: "Sync Ratio",
      AvailableBalance: "Avbl Balance",
      WalletBalance: "Wallet Balance",
      ErrMsg: "Incorrect API key, please check the account settings!",
    },
    TransferModal: {
      UniversalTransfer: "Universal Transfer",
      Transfer: "Transfer",
      AssetTransfer: "Fund transfer",
      TransferHistory: "Transfer record",
      Cancel: "Cancel",
      AccountSelect: "Account",
      From: "From",
      To: "To",
      Currency: "Coin",
      AvailableFunds: "Avbl Funds",
      TransferAmount: "Transfer Amount",
      All: "All",
      SpotAccount: "Spot",
      FutureAccount: "USDⓈ-M Futures",
      SwapAccount: "COIN-M Futures",
      FundsAccount: "Funding",
    },
  },

  AIDashboard: {
    AIDashboardDetail: {
      Group: "Transaction Group",
      Export: "Export",
      Search: "Search",
      Optional: "Mine",
      Market: "Market",
      Symbol: "Symbols",
      Sort: "Rank",
      Price: "Price",
      UpDown: "24h %",
      TREND: "Trend Tools",
      STRONG_BACK: "Buy Low Sell High",
      SHOCK_GRID: "Grid",
      SMART_SHOCK_GRID: "AI Grid",
      SHOCK_REVERSE: "Buy Low Sell High",
      RATE_GRID: "Rate Grid",
      STRONG_GRID: "Strong Grid",
      MARKETMAKER: "Market Maker",
      ARB_OPTION: "Option Arbitrage",
      ARB_FACTOR: "Factor Arbitrage",
      ASTOCK_EXECUTE_MULTI: "A Stock Execute",
      SOR: "Smart Order Routing",
      TREND_CONTINUE_ROBOT: "Second Wave Of Trend",
      MarkPrice: "Mark Price",

      The24hHigh: "24h High",
      The24hLow: "24h Low",
      The24hVolume: "24h Volume",
      The24hQty: "24h Amount",
      SelectEnvironment: "Environment",
      TestEnvironment: "Test",
      ProductionEnvironment: "Production",
      AwsEnvironment: "AWS",
      LinodeEnvironment: "Linode",
      Linode2Environment: "Linode2",
      Local211Environment: "Local211",
      SelectSubAccount: "Sub Account",
      PleaseCancelUpdate: "Please cancel update",
      UnknownStrategyMsg: "Unknown Strategy",
      StartSuccessfulMsg: "Start Successful",
      StartFailedMsg: "Start Failed",
      UpdateSuccessfulMsg: "Updated",
      UpdateFailedMsg: "Update Failed",
      UnknownSymbolMsg: "Unknown Pair",
      GetSubscribeListFailed: "Subscription list retrieval failed",
    },
    KChart: {
      HistoryMarks: "Order History",
      CurrentMarks: "Open Orders",
    },
    Form: {
      MaxOrderAmount: "Maximum Qty",
      ImpactLevel: "Level",
      ImpactMultiple: "Multiple(x)",
      Auto: "AI",
      Manual: "Manual",
      Level: "Level",
      LevelSetting: "Order Settings",
      LevelStart: "Start Position",
      LevelTotal: "Pending orders",
      LevelInterval: "Price Interval",
      PositionSetting: "Position Setting",
      MultiplePositionsAvailable: "Avbl Long Position",
      ShortPositionAvailable: "Avbl Short Position",
      TargetPosition: "Target",
      AmountAvailable: "Avbl Amount",
      AllAmountAvailable: "Total Avbl Amount",
      AvailableAmountOnce: "Avbl Once",
      RobotN: "Only Trade The Top N Liquidity",
      PitchRatio: "Range Ratio",
      OrderStep: "Split Order Spacing",
      OrderQuantity: "Amount",
      Isolate: "Isolated",
      Cross: "Cross",
      Spot: "Spot",
      Available: "Available",
      Borrowed: "Borrowed",
      IsolatedMarginNetAssets: "Isolated Net Assets",
      CrossMarginNetAssets: "Cross Net Assets",
      SpotNetAssets: "Spot Balance",
      CanBeOpened: "Max",
      Balance: "Balance",
      CustomInstanceName: "Custom Instance Name",
      Reset: "Reset",
      Cancel: "Cancel",
      NotLoggedIn: "Not Logged In",
      InsufficientVipLevel: "Locked",
      Startup: "Start",
      Update: "Update",
      SmartPrice: "SPF",
      Gear: "Gear",
      Window: "Window",
      Cycle: "Period",
      LongCycle: "Long Period",
      ShortCycle: "Short Period",
      PriceGap: "Price Range",
      Minimum: "Min",
      Maximum: "Max",
      Volatility: "Volatility",
      Trend: "Trend",
      OrderBook: "OIF",
      OFIF: "OFIF",
      MaximumInventory: "Max Inventory",
      EnableTimeSharing: "Enable Timeline",
      EnableLiquidation: "Enable Liquidation",
      Clearance: "Close",
      AllClearance: "Close All",
      OnlyMaker: "Only Maker",
      FollowBook: "Follow OrderBook",
      AuthorizedObserver: "Authorize Observer",
      PositionAvailable: "Avbl Position",
      KlineCycle: "K Line Cycle",
      OpenPositionQuantile: "Open Price",
      ClosePositionQuantile: "Close Price",
      Leverage: "Leverage",
      IsLong: "Long",
      IsUseCurrentPosition: "Inherit position",
      IsNotReQuote: "Quote only once",
      IsOpen: "Open Position",
      Side: "Side",
      OnlyLong: "Long",
      OnlyShort: "Short",
      LongAndShort: "Long/Short",
      StrongDecision: "Strong Assessment",
      BuyOrderSpacingRatio: "Buy Rang Ratio",
      SellOrderSpacingRatio: "Sell Rang Ratio",
      EquivalentRatio: "Geometric",
      EquivalentDifferences: "Arithmetic",
      TakeProfitStopLoss: "TP/SL ",
      TakeProfit: "TP",
      StopLoss: "SL",
      PriceRange: "Price Range",
      HighPoint: "High",
      LowPoint: "Low",
      BuyOrder: "Buy",
      SellOrder: "Sell",
      PendingOrderLogarithm: "Pending order logarithm",
      SymbolSettings: "Symbol Settings",
      RateGridLongSymbol: "Long Symbol",
      RateGridTradeSymbol: "Short Symbol",
      AddCurrentSymbol: "Add Symbol",
      AddCurrentCurrency: "Add Symbol",
      GoBack: "Back",
      Confirm: "Confirm",

      ExecConfig: "Executor Configuration",
      ExecMinOrderQty: "Minimum Order Quantity",
      ExecMaxSplitOrder: "Max Split Orders",
      ExecOrderTimeout: "Order Timeout",
      ExecTimeout: "Total Timeout",
      ExecTimeoutTaker: "Timeout Taker Action",
    },
    Command: {
      Running: "Running",
      History: "History",
      ObserverRunning: "Observer Running",
      ObserverHistory: "Observer History",
      RiskRunning: "RC Running", // Risk Control Running
      RiskHistory: "RC History", // Risk Control History

      Instance: {
        //Options
        Equity: "Account Equity",
        TotalEquityUsd: "Account Equity (USD)",
        Im: "Initial Margin Rate",
        Mm: "Maintenance Margin Rate",
        TotalFloating_Profit_Loss_Usd: "Profit and Loss(USD)",
        TotalProfit_Loss: "Profit and Loss",
        Roi: "ROI",
        Total_Delta: "Delta",
        Total_Gamma: "Gamma",
        Total_Vega: "Vega",
        Total_Theta: "Theta",
        Total_Rho: "Rho",

        SubAccount: "Sub Account",
        All: "All",
        SelectionPeriod: "Period",

        StopInstance: "Stop Instance",
        ChangeParameters: "Change Params",
        ReuseParameters: "Reuse Params",
        PositionOverview: "Overview",
        TradeRecord: "Trade Record",
        InitiallyAvailable: "Fund Size",
        TotalTransaction: "Amount",
        StrategicBenefits: "Realized Profit",
        TotalHoldingProfit: "UnRealized Profit",
        TotalProfit: "Total Profit",
        CurrentPosition: "Open Position",
        PositionDirection: "Side",
        BuyAvgPrice: "Buy Avg Price",
        SellAvgPrice: "Sell Avg Price",
        TransactionQty: "Amount",
        AverageHoldingPrice: "Position Avg Price",
        MarkPrice: "Mark Price",
        Notional: "Notional",
        HoldingProfit: "UnRealized Profit",
        PolicyDetails: "Details",
        IncomeLevel: "PNL Level",
        HighIncomeLevel: "Peak Level",
        LowIncomeLevel: "Bottom Level",
        MaximumDrawdown: "Max Drawdown",
        SingleProfit: "Single Profit",
        MaximumProfit: "Max Profit",
        MinimumProfit: "Min Profit",
        MaximumLoss: "Max Loss",
        MinimumLoss: "Min Loss",
        ProfitAndLossTimes: "PNL Times",
        ProfitTimes: "Profit Times",
        LossTimes: "Loss Times",
        ProfitAndLoss: "PNL",
        TotalProfit2: "Total Profit",
        TotalLoss: "Total Loss",
        TotalRebate: "Total Fees",
        NetProfit: "Net Profit",
        NetPosition: "Net Position",
        ProfitAndLossRatio: "PNL(%)",
        TransactionVolume: "Volume",
        Buy: "Buy",
        Sell: "Sell",
        Total: "Total",
        TransactionRole: "Trade Role",
        TakerCount: "Taker Times",
        MakerCount: "Maker Times",
        TakerQty: "Taker Amount",
        MakerQty: "Maker Amount",
        WinRate: "Win Rate",
        Detail: "Detail",
        SubAccountBalance: "Sub Account Balance ",
        StrategyStatus: "Status",
        AccountType: "Type",
        StartupTime: "Start",
        StopConfirm: "Stop instance?",
        StopConfirmTips: "The operation cannot be undone!",
        Confirm: "Confirm",
        Cancel: "Cancel",
        ActionSuccess: "Successful",
        ActionFailed: "Failed",

        CurrentPositionDirection: "Side",
        Time: "Time",
        CumBuyU: "CUM Buy(U)",
        CumBuyC: "CUM Buy(C)",
        AvgBuyPrice: "Avg Buy Price",
        CumSellU: "CUM Sell(U)",
        CumSellC: "CUM Buy(C)",
        AvgSellPrice: "Avg Sell Price",
        InitPosition: "Init Position Qty",
        CurrPosition: "Curr Position Qty",
        CurrVolumn: "Curr Volume",
        CurrValue: "Curr Value",

        GroupId: "Set ID",
        BuyAmount: "Buy Qty",
        SellAmount: "Sell Qty",
        BuyQty: "Buy Amount",
        SellQty: "Sell Amount",
        Profit: "PNL",
        OrderFee: "Fee",
        StartTime: "Start",
        RunTime: "Run Time",
        Action: "Action",

        Day: "d",
        Hour: "h",
        Minute: "m",
        Second: "s",

        ProfitModal: {
          YieldCurve: "Yield Curve",
          CumulativeIncome: "Cumulative PNL",
          AverageValue: "Average",
          SingleIncome: "PNL Per Trade",
          IsLong: "Long",
          IsShort: "Short",
        },
        GroupModal: {
          IntraGroupTransactionRecord: "Trade Record",
          ShowOnlyTradedOrders: "Show executed orders only",
          OrderTime: "Order Time",
          TransactionTime: "Trade Time",
          TransactionDirection: "Side",
          TargetPrice: "Target Price",
          TargetAmount: " Target Amount",
          AvgPrice: "Avg Price",
          Vol: "Volume",
          Qty: "Amount",
          Role: "Role",
          Status: "Status",
          OrderFee: "Fee",
          Total: "Total",
          Record: "Records",
        },
      },
    },
  },
  ModalInfo: {
    ConfirmCancelExchange: "Do you confirm to cancel this pending order?",
    ConfirmCancelBail: "Do you confirm the cancellation of the delegation?",
    ConfirmCancelAllExchange: "Do you confirm to cancel all pending orders?",
    ConfirmMarketClosePosition: "Do you confirm market price closing?",
    ConfirmOrder: "Do you confirm the order?",

    ConfirmTips: "This operation cannot be undone!",
    Yes: "Yes",
    No: "No",
    CancelAction: "Cancel operation",
    ActionSuccess: "Operation successful",
    ActionFailed: "Operation failed",
  },

  ExchangeName: {
    Binance: "Binance",
    Okex: "OKX",
    Huobi: "Huobi",
    Coinbase: "Coinbase",
    Deribit: " Deribit",
    AISelect: "AI Select",
    Bybit: "Bybit",
  },
  AccountType: {
    Spot: "Spot",
    Future: "USDⓈ-M",
    Swap: "COIN-M",
    Option: "Option",
    Index: "Index",
  },
  OkxAccountType: {
    SPOT: "Spot",
    SWAP: "Perpetual",
    SWAPALL: "Perpetual Contract",
    FUTURES: "Delivery",
    FUTURESALL: "Delivery Contract",
  },
  BybitAccountType: {
    SPOT: "Spot",
    LINEAR: "USD Contract",
    INVERSE: "Inverse Contract",
    OPTION: "Option",
    spot: "Spot",
    linear: "USD Contract",
    inverse: "Inverse Contract",
    Perpetual: "Perpetual",
    Inverse: "Inverse",
  },
  Kchart: {
    SaveStudy: "Save Study",
    LoadStudy: "Load Study",
  },
  KChartBox: {
    SelectInterval: "Select Intervals",
    Edit: "Edit",
    Save: "Save",
  },
  FuturesSymbol: {
    GC0W_CMX: "Golden Master Company",
    SI0W_CMX: "Silver Master Company",
    HG0W_CMX: "Copper Main Company",
    PL0W_NMX: "Platinum Main Company",
    CL0W_NMX: "WTI crude oil main connection",
    NG0W_NMX: "Natural gas main company",
    RB0W_NMX: "RBOB gasoline main company",
  },
  AstockSymbol: ASTOCK_LIST,
};
